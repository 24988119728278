<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Yetki Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Yetki Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="role_adi"
                v-model="form.role_adi"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            striped
            hover
            outlined
            responsive
            :head-variant="'light'"
            class="mb-0"
            :fields="fields"
            :items="form.authority"
          >
            <template #cell(title)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox :checked="data.value" @change="handlerCheck(data)" switch size="lg" />
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex justify-content-end">
            <b-button squared size="lg" variant="secondary" @click="$emit('handlerClose', false)">
              <i class="fad fa-times-square"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    expo.fields = ref(['title', 'listeleme', 'ekleme', 'duzenleme', 'silme']);

    expo.form = ref({});

    const { updateData } = toRefs(props);

    expo.handlerCheck = (data) => {
      const field = data.field;
      const index = data.index;
      expo.form.value.authority[index][field.key] = !expo.form.value.authority[index][field.key];
    };

    // const handlerCanceled = () => {
    //   expo.form.value.authority = [];
    //   Object.keys(expo.authority.value).map((key) => {
    //     expo.form.value.authority.push(expo.authority.value[key]);
    //   });
    // };

    // handlerCanceled();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);

      store.dispatch('rolleGuncelle', expo.form.value).then((res) => {
        if (res.data.success === true) {
          toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          context.emit('show', false);
          if (expo.form.value.k_no == null) {
            expo.resetForm();
          }
        }
      });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
